/* eslint  max-len: 0 */
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
// import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import {
  addToBetSlip,
  deleteFromBetSlip,
} from 'redux/configs/betConfigsSlice';
import { getEventMarkets } from 'redux/Event-markets/eventMarketSlice';
import formatDateTime from 'components/helpers/formatDateTime';
import chevronRight from '../../../assets/images/mathys/svg/chevron-right.svg';
import baseUrls from '../../../config';

const SportItem = ({ event }) => {
  const dispatch = useDispatch();
  const imgUrl = baseUrls.BASE_URL_IMG;
  const { bets } = useSelector((store) => store.betSlip);
  const { cleanedMarkets } = useSelector((store) => store.topMatchesMkts);
  const {
    outcome, info, id, time, number, count,
  } = event;

  const [outComes, setOutComes] = useState(outcome);

  const handleButtonClick = (match, market, uniqueId) => {
    const existingItem = bets.find((item) => item.uniqueId === uniqueId);
    if (existingItem) {
      dispatch(deleteFromBetSlip(uniqueId));
    } else {
      dispatch(addToBetSlip({
        match,
        odds: market.odds,
        team: market.name,
        market: market.market.name,
        matchId: match.id,
        id: market.id,
        uniqueId: market.uniqueId,
        inBetSlip: market.inBetSlip,
        matchNumber: match.number,
        pick: `${match.number}*${market.market.id}*${market.outcome_id}`,
      }));
    }
  };

  useEffect(() => {
    setOutComes(outcome.filter((o) => o.market.id === event.default_market));
  }, [outcome, event]);

  const formattedDate = formatDateTime(time);
  return (
    <>

      {outComes && outComes.length > 0 && (
      <li key={event.id + uuidv4()} className="">
        <div className="card">
          <div className="line-up">
            <div className="left">
              <img src={`${imgUrl}${info.sport.icon}`} alt="" />
              <div className="team">
                <span>{info.teamhome.name}</span>
                <span>{info.teamaway.name}</span>
              </div>
            </div>
            <div className="right">
              {outComes.map((x) => {
                const itemInBetSlip = bets.find((bet) => bet.uniqueId === x.uniqueId);
                return (
                  <button
                    key={x.id}
                    type="button"
                    className={itemInBetSlip ? 'btn btn btn-secondary marked-btn' : 'btn btn btn-secondary'}
                    onClick={() => {
                      handleButtonClick(event, x, x.uniqueId);
                    }}
                  >
                    <span>
                      {cleanedMarkets[`${x?.market?.id}-${x?.id}`] ? cleanedMarkets[`${x?.market?.id}-${x?.id}`] : x.display }
                    </span>
                    {x.odds}
                  </button>
                );
              })}
            </div>
          </div>
          <div className="line-down">
            <div className="d-left">
              <span>{number}</span>
              <span className="hour">{formattedDate}</span>
            </div>
            <div className="d-right">
              <span>{info.tournament.name}</span>
              <span>
                <NavLink
                  className="text-white text-decoration-none"
                  to="/dashboard/match-details"
                  onClick={() => {
                    dispatch(getEventMarkets(id));
                    localStorage.setItem('eventDetails', JSON.stringify(event)); // To be used when page is refreshed to refetch event details
                  }}
                >
                  +
                  {count}
                  <img src={chevronRight} alt="" />
                </NavLink>
              </span>
            </div>
          </div>
        </div>
      </li>
      )}

    </>
  );
};
SportItem.propTypes = {
  event: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default SportItem;
