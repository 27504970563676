/* eslint  max-len: 0 */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import '../../../styles/_sports.scss';
import { getTournamentMatches } from 'redux/tournament-matches/tournamentMatchesSlice';
import translationKeys from 'translations/translationKeys';
import Loading from 'components/common/Loading';
import Error from 'components/common/Error';
import SportItem from './SportItem';

const UniversalComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { msg154 } = translationKeys;
  const sportSelected = JSON.parse(localStorage.getItem('sportSelected')); // Set in App.jsx
  const {
    tournamentMatches, isLoading, error, successGetTournamentMatches,
  } = useSelector((store) => store.tournamentMatches);
  const { topMatchesMkts } = useSelector((store) => store.topMatchesMkts);
  let marketNames;
  if (topMatchesMkts && topMatchesMkts[sportSelected]) {
    const sport1Markets = topMatchesMkts[sportSelected];
    marketNames = sport1Markets.map((item) => JSON.parse(item));
  }
  const tournamentId = JSON.parse(localStorage.getItem('tournamentId')); // Set in LeftSideBar.jsx
  const marketId = localStorage.getItem('marketId'); // Set below
  // When page is refreshed and path is /dashboard/matches, call tournament matches.
  useEffect(() => {
    if (!successGetTournamentMatches && pathname === '/dashboard/matches') {
      dispatch(getTournamentMatches({ tournamentId, marketId: marketId || '' }));
    }
  }, [pathname, successGetTournamentMatches, dispatch]);
  return (
    <>
      <div className="list-dashboard">
        <div className="content-wrap">
          <div className="tab-content">
            <div className="zone-nav">
              <ul className="nav nav-pills list-inline">
                {marketNames && marketNames.map((item) => (
                  <li className="nav-item list-inline-item" role="presentation" key={uuidv4()}>
                    <button
                      type="button"
                      className="nav-link"
                      onClick={() => {
                        // localStorage.setItem('marketSelected', JSON.stringify(item));
                        localStorage.setItem('marketId', item.marketId); // To be used refetch tournament matches when page is refreshed
                        dispatch(getTournamentMatches({ tournamentId, marketId: item.marketId }));
                        navigate('/dashboard/matches');
                      }}
                    >
                      {item.marketName}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
            <div className="tab-content">
              <div
                className="match-result"
              >
                {isLoading && <Loading />}
                {error && <Error text={t(error)} />}
                {!isLoading && !error && (
                <ul className="list-unstyled">
                  {(tournamentMatches && tournamentMatches.length > 0 && tournamentMatches.map((item) => {
                    const { matches } = item;
                    return matches && matches.length > 0 && matches.map((m) => <SportItem event={m} key={m.id} />);
                  })) || <Error text={t(msg154)} />}
                </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UniversalComponent;
