/* eslint  max-len: 0 */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { showRechargeConfirmBox } from 'redux/deposit/depositSlice';
import { showBonRechargeConfirmBox } from 'redux/deposit/bonDepositSlice';
import { v4 as uuidv4 } from 'uuid';
import translationKeys from 'translations/translationKeys';
import depositwhite from '../../../../assets/images/mathys/svg/depositwhite.svg';

const Deposit = () => {
  const dispatch = useDispatch();
  const [bonAmount, setBonAmount] = useState('');
  const { t } = useTranslation();
  const [disableBon, setDisableBon] = useState(true);
  const [disableDirect, setDisableDirect] = useState(true);
  const handleChange = () => {
    const bon = document.getElementById('bonAmountlabel').value;
    const code = document.getElementById('code').value;
    const amoutLable = document.getElementById('amountRDlabel').value;
    if (code !== '' && amoutLable !== '') {
      setDisableDirect(false);
    }
    if (code === '' || amoutLable === '') {
      setDisableDirect(true);
    }
    if (bon !== '') {
      setDisableBon(false);
    }
    if (bon === '') {
      setDisableBon(true);
    }
  };
  const handleButtonClick = (amt) => {
    setBonAmount(amt);
  };
  const { voucherProviders } = useSelector((store) => store.voucherProviders);
  const defaultVouchers = [500, 1000, 2000, 3000, 4000, 10000, 20000];
  const arr = (voucherProviders && voucherProviders.length > 0 && voucherProviders !== undefined && voucherProviders) || defaultVouchers;
  const handleBonRecharge = (e) => {
    e.preventDefault();
    dispatch(showBonRechargeConfirmBox());
  };

  const handleChangeRecharge = (e) => {
    e.preventDefault();
    const amt = e.target.elements.amountRDlabel.value;
    const acc = e.target.elements.code.value;
    if (amt === '0' || acc === '') {
      return;
    }
    dispatch(showRechargeConfirmBox({
      amt,
      acc,
    }));
  };
  const {
    msg21, msg133, msg121, msg54, msg55, msg56, msg4, msg134,
  } = translationKeys;
  return (
    <>
      <div className="details history">
        <div className="header">
          <img src={depositwhite} alt="" />
          {t(msg21)}
        </div>
        <div className="content">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">{t(msg133)}</button>
            </li>
            <li className="nav-item" role="presentation">
              <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">{t(msg121)}</button>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex="0">
              <form action="" onSubmit={handleBonRecharge} className="recharge-form">
                <div className="content-grid">
                  <div className="mb-3 input-wrap">
                    <label htmlFor="bonAmountlabel" className="form-label">
                      {t(msg54)}
                      <input
                        type="text"
                        className="form-control deposit-input"
                        id="bonAmountlabel"
                        placeholder=""
                        required
                        value={bonAmount}
                        onChange={(e) => {
                          setBonAmount(e.target.value);
                          handleChange();
                          localStorage.setItem('couponAmt', JSON.stringify(e.target.value));
                        }}
                      />
                    </label>
                  </div>
                  <div className="block bl-multiple mb-4 amounts">
                    {arr && arr.map((amt) => (
                      <button
                        key={uuidv4()}
                        id={amt}
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => {
                          setDisableBon(false);
                          handleButtonClick(amt);
                          localStorage.setItem('couponAmt', JSON.stringify(amt));
                        }}
                      >
                        <span>{amt}</span>
                      </button>
                    ))}
                  </div>
                  <button
                    type="submit"
                    className="btn btn-secondary"
                    disabled={disableBon}
                  >
                    <span>{t(msg55)}</span>
                  </button>
                </div>
              </form>
            </div>
            <div className="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabIndex="0">
              <form action="" onSubmit={handleChangeRecharge} className="recharge-form">
                <div className="content-grid">
                  <div className="mb-3 input-wrap">
                    <label htmlFor="code" className="form-label">
                      {t(msg56)}
                      <input
                        type="text"
                        className="form-control deposit-input"
                        id="code"
                        placeholder={t(msg4)}
                        onChange={handleChange}
                        required
                      />
                    </label>
                  </div>
                  <div className="mb-3 input-wrap">
                    <label htmlFor="amountRDlabel" className="form-label">
                      {t(msg54)}
                      <input
                        type="text"
                        className="form-control deposit-input"
                        id="amountRDlabel"
                        placeholder={t(msg134)}
                        onChange={handleChange}
                        required
                      />
                    </label>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-secondary"
                    disabled={disableDirect}
                  >
                    <span>{t(msg55)}</span>
                  </button>
                </div>
              </form>
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default Deposit;
