/* eslint max-len: 0 */
/* eslint jsx-a11y/click-events-have-key-events: 0 */
/* eslint jsx-a11y/no-static-element-interactions: 0 */
import React, { useState, useEffect } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { getMeilleursComp } from 'redux/meilleursCompetition/meilleursCompSlice';
import { getTournamentMatches } from 'redux/tournament-matches/tournamentMatchesSlice';
import { getCategoryTournaments } from 'redux/category-tournaments/categoryTournamentsSlice';
import { getAllSports, selectSport } from 'redux/all-sports/allSportsSLice';
import '../../../styles/_dashboard.scss';
import { getCategories } from 'redux/categories/categoriesSlice';
import translationKeys from 'translations/translationKeys';
import { getTomatchesMkts } from 'redux/meilleursParis/topmatchesMktsSlice';
import MobileSideMenu from './MobileSideMenu';
import fire from '../../../assets/images/mathys/svg/fire.svg';
import circle from '../../../assets/images/mathys/svg/play-circle.svg';
import camera from '../../../assets/images/mathys/svg/camera-reels.svg';
import arrowRight from '../../../assets/images/mathys/svg/arrow-right.svg';
import sportImage from '../../../assets/images/mathys/png/sport.png';
import homeImage from '../../../assets/images/mathys/png/home.png';
import defaultImage from '../../../assets/images/mathys/png/football.png';
// import jackpotImage from '../../../assets/images/mathys/png/jackpot.png';
import baseUrls from '../../../config';

const LeftSideBar = () => {
  const { pathname } = useLocation(); // Stay on the current path when clicked on 'Meilleures Compétitions'  since 'Meilleures Compétitions' only opens a menu
  const navigate = useNavigate();
  const { t } = useTranslation();
  const imgUrl = baseUrls.BASE_URL_IMG;
  const dispatch = useDispatch();
  // const [mobileMenu, setMobileMenu] = useState('hide-mobile-sidebar');
  const [className, setClassName] = useState('hide-sub-menu desktop');
  const [tClassName, setTClassName] = useState('tournament desktop');
  const [middleMenuClass, setMiddleMenuClass] = useState('content-sport desktop');
  const [openItemId, setOpenItemId] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const { allSports } = useSelector((store) => store.allSports);
  const { meilleursComp, meilleursCompSuccess } = useSelector((store) => store.meilleursComp);
  const { categories } = useSelector((store) => store.categories);
  const { tournaments } = useSelector((store) => store.tournaments);
  const { allTopMatches } = useSelector((store) => store.allTopMatches);
  const { cleanedMarkets } = useSelector((store) => store.topMatchesMkts);
  const text = JSON.parse(localStorage.getItem('text')); // Set below
  const buttonId = JSON.parse(localStorage.getItem('buttonId')); // Set below

  const hideSideBar = () => {
    setClassName('hide-sub-menu');
    setTClassName('tournament');
  };

  const toggleAcc = (itemId) => {
    setOpenItemId((prevItemId) => (prevItemId === itemId ? null : itemId));
  };

  const showSideBar = () => {
    setClassName('sub-menu');
    setTClassName('tournament');
    setMiddleMenuClass('content-sport');
  };
  const showTournamentsBar = () => {
    setClassName('add-sub-menu-width');
    setTClassName('add-tournament-width');
    setMiddleMenuClass('add-content-sport-width');
  };
  const clearAllMenus = () => {
    setClassName('hide-sub-menu');
    setTClassName('tournament');
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (pathname.includes('match') || pathname.includes('paris')) {
      dispatch(getAllSports());
      showSideBar();
    } else {
      hideSideBar();
    }
    if (Object.keys(cleanedMarkets).length < 1) {
      dispatch(getTomatchesMkts());
    }
  }, [dispatch, pathname, cleanedMarkets]);

  useEffect(() => {
    if (allTopMatches.length > 0) {
      localStorage.setItem('topSportSelected', allTopMatches[0].id);
    }
  }, [allTopMatches]);

  useEffect(() => {
    if (!meilleursCompSuccess) {
      dispatch(getMeilleursComp());
    }
  }, [meilleursCompSuccess, dispatch]);

  const {
    msg11, msg15, msg10, msg9, msg12, msg13,
  } = translationKeys;
  const list = [

    {
      id: uuidv4(), img: fire, path: 'meilleurs-paris', text: msg9,
    },
    {
      id: uuidv4(), img: circle, path: 'today-matches', text: msg10,
    },
    // {
    //   id: uuidv4(), img: camera, text: 'Direct', path: 'direct', className: 'normal', func: showSideBar,
    // },
    {
      id: uuidv4(), img: camera, text: msg11, path: pathname, className: 'normal',
    },

  ];

  const links = [
    {
      id: uuidv4(), path: 'home', text: msg12, img: homeImage, className: 'btn btn-secondary',
    },
    {
      id: uuidv4(), path: 'meilleurs-paris', text: msg13, img: sportImage, className: 'btn btn-secondary show-sports-memu',
    },
    // {
    //   id: uuidv4(), path: 'jackpot', text: 'Jackpot', img: jackpotImage, className: 'btn btn-secondary', func: hideSideBar,
    // },
  ];
  return (

    <>
      <div className="sidebar desktop">
        <div className="group-btn">
          {links.map((link) => (
            <NavLink
              to={link.path}
              key={link.id}
              className={({ isActive }) => (isActive ? `${link.className} selected` : link.className)}
              onClick={() => {
                localStorage.setItem('buttonId', JSON.stringify(msg9)); // Set active button to Top matches
              }}
            >
              <img src={link.img} alt="" />
              {t(link.text)}
            </NavLink>
          ))}
        </div>
      </div>
      <div className={className} id="sub-menu">
        <div className="content-sub-menu">
          <div className={middleMenuClass}>
            <ul className="list-unstyled">
              {list.map((item) => (
                <li key={item.id}>
                  <button
                    type="button"
                    className={buttonId === item.text ? 'btn normal selected' : 'btn normal'} // Set active button
                    onClick={() => {
                      if (item.path === pathname) {
                        dispatch(getMeilleursComp());
                        showTournamentsBar();
                      } else {
                        navigate(item.path);
                      }

                      localStorage.setItem('text', JSON.stringify(item.text)); // Used to determine which menu items to display on third menu
                      localStorage.setItem('buttonId', JSON.stringify(item.text)); // Set active button
                    }}
                  >
                    <img src={item.img} alt="" />
                    <span>{t(item.text)}</span>
                  </button>
                </li>
              ))}

              {allSports.map((item) => (
                <li key={item.id}>
                  <button
                    type="button"
                    className={buttonId === item.name ? 'btn normal selected' : 'btn normal'}
                    onClick={() => {
                      dispatch(selectSport(item.id));
                      dispatch(getCategories(item.id));
                      showTournamentsBar();
                      localStorage.setItem('text', JSON.stringify(item.name)); // Used to determine which menu items to display on third menu
                      localStorage.setItem('buttonId', JSON.stringify(item.name)); // Set active button
                    }}
                  >
                    <img
                      src={item.icon ? imgUrl + item.icon : defaultImage}
                      alt=""
                    />
                    <span>{item.name}</span>
                  </button>
                </li>
              ))}
            </ul>

          </div>
          <div className={tClassName}>
            <ul className="list-unstyled">
              {text === 'Meilleures Compétitions' && (
                <li className="navs top-event title">
                  <span>
                    {t(msg11)}
                  </span>
                  <div>
                    <button
                      type="button"
                      id="major-tournaments"
                      className="btn btn-close"
                      onClick={() => {
                        showSideBar();
                      }}
                    />
                  </div>
                </li>
              )}
              {text === 'Meilleures Compétitions' && (
                meilleursComp.map((m) => (
                  <NavLink
                    className="navs "
                    key={m.id}
                    to="/dashboard/matches"
                    onClick={() => {
                      dispatch(getTournamentMatches({ tournamentId: m.id }));
                      localStorage.setItem('tournamentId', JSON.stringify(m.id)); // Make tournamentId available for use at UniversalComponent.jsx
                      showSideBar();
                    }}
                  >
                    <span>{m.name}</span>
                    <img src={arrowRight} alt="" />
                  </NavLink>
                ))
              )}
              {text !== 'Meilleures Compétitions' && (
              <li className="navs top-event title">
                <span>{t(msg15)}</span>
                <div>
                  <button type="button" className="btn btn-close" onClick={showSideBar} />
                </div>
              </li>
              )}
              {text !== 'Meilleures Compétitions' && (
                categories.map((item) => (
                  <div key={item.id} className="cat-cont">
                    <button
                      type="button"
                      className="navs tnmt-btn"
                      onClick={() => {
                        dispatch(getCategoryTournaments(item.id));
                        toggleAcc(item.id);
                      }}
                    >
                      <span>{item.name}</span>
                    </button>
                    {tournaments.categoryId === item.id && (
                      <div className={openItemId === item.id ? 'openAcc tmts' : 'acc-closed'}>
                        {tournaments.tournaments.map((t) => (
                          <NavLink
                            className="navs "
                            key={t.id}
                            to="/dashboard/matches"
                            onClick={() => {
                              // dispatch(getTournamentId(t.id));
                              dispatch(getTournamentMatches({ tournamentId: t.id }));
                              if (!isMobile) {
                                showSideBar();
                              } else {
                                clearAllMenus();
                              }
                              // localStorage.setItem('marketSelected', JSON.stringify('1x2'));
                              localStorage.setItem('tournamentId', JSON.stringify(t.id)); // To be used at UniversalComponent.jsx to refetch data when page is refreshed
                            }}
                          >
                            <span>{t.name}</span>
                            <img src={arrowRight} alt="" />
                          </NavLink>
                        ))}
                      </div>
                    )}

                  </div>
                ))
              )}
            </ul>
          </div>
        </div>
      </div>
      <MobileSideMenu />
    </>
  );
};

export default LeftSideBar;
