/* eslint prefer-destructuring: 0 */
/* eslint jsx-a11y/label-has-associated-control: 0 */
/* eslint  jsx-a11y/no-static-element-interactions: 0 */
/* eslint  jsx-a11y/click-events-have-key-events: 0 */
/* eslint  max-len: 0 */
import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import translationKeys from 'translations/translationKeys';
import getLanguage from 'components/helpers/getLanguage';
import {
  deleteFromBetSlip, updateStake,
  clearBetSlip, stepStake,
  calculateGains,
  calculateOdds,
  toggleMultipleBet,
  applyBonus,
  calculateTax,
} from 'redux/configs/betConfigsSlice';

import { resumeBet, showModal } from 'redux/place-bet/resumeBetsSlice';
import { formatCurrency } from 'components/helpers/currencyOptions';
// import { getDeviceImei } from 'components/helpers/printTicketData';
import { userObj } from 'components/common/requestBody';
import { IoMdClose } from 'react-icons/io';
import '../../../styles/_rightsidebar.scss';
import plus from 'assets/images/mathys/svg/plus.svg';
import ShowAlert from 'components/common/ShowAlert';
import Modal from 'components/common/Modal';
import ResetPinComponent from 'components/sessions/ResetPinComponent';
import trash from '../../../assets/images/mathys/svg/trash.svg';
import defaultImage from '../../../assets/images/mathys/png/football.png';
import dash from '../../../assets/images/mathys/svg/dash.svg';
import ticket from '../../../assets/images/mathys/svg/ticket.svg';
import baseUrls from '../../../config';

const RightSideBar = () => {
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const { t } = useTranslation();
  const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
  // const imei = getDeviceImei();
  // const ipAddress = JSON.parse(sessionStorage.getItem('ipAddress'));
  const [mobileBetSlipClass, setMobileBetSlipClass] = useState('betslip hide-mobile-betslip');
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const {
    odds, bets, betType, stake, finalWinnings,
    multiple, simple, multipleSimple, exciseTaxConfig, TVA,
    potentialWinnings, potentialWinningsWithBonus, minStake, maxStake, exciseTax, tvaConfig,
    alerts, integral, bonus, integralTickets, bettingOptions, simpleTickets,
    numIntegralTickets, pick, stakeWithoutTax, bonusPercent, stepValue,
  } = useSelector((store) => store.betSlip);
  const { resumeBetLoading, betStatus, showPreview } = useSelector((store) => store.resumeBets);
  const {
    successfetchTicket, verifyTicketMsg, isTicketLoading, isErrorVerifyTckt,
  } = useSelector((store) => store.ticketData);
  const { showRechargeConfirm, rechargeSuccess, isdepositLoading } = useSelector((store) => store.depositData);
  const { checkWithdrawLoading } = useSelector((store) => store.withdrawalChecked);
  const {
    showBonRechargeConfirm, bonRechargeSuccess, errorBonRecharge, isBonRechargeLoading,
  } = useSelector((store) => store.bonRechargeData);
  const { successGetBalance, isbalanceLoading, errorGetBalance } = useSelector((store) => store.balance);
  const { canWithdraw, canNotWithdraw, failedCheckWithdraw } = useSelector((store) => store.withdrawalChecked);
  const { isPlaceBetLoading } = useSelector((store) => store.placeBets);
  const { isCancelTicketLoading } = useSelector((store) => store.ticketCanceled);
  const {
    withdrawWithCodeLoading, canPrint,
  } = useSelector((store) => store.withdrawWithCode);
  const {
    ticketPaid, showPayConfirm, failedPayTicket, isPaidLoading,
  } = useSelector((store) => store.ticketPaid);
  const { loadingHistory, histMsg } = useSelector((store) => store.history);
  const {
    ticketCanceled, showCancelConfirm, failedCancelTicket,
    ticketNotRegistered, ticketAlreadyCanceled,
  } = useSelector((store) => store.ticketCanceled);
  const { success, msg } = useSelector((store) => store.placeBets);
  const {
    resetLoading, resetMessage, resetSuccessMsg, resetPinForm,
  } = useSelector((store) => store.resetPinData);
  const { currencyConfigs } = useSelector((store) => store.currencyConfigs);
  const imgUrl = baseUrls.BASE_URL_IMG;
  const betTypes = (`${bettingOptions}`).split(',');
  const simpleBetIndex = betTypes.lastIndexOf('SIMPLE');
  const multipleBetIndex = betTypes.lastIndexOf('MULTIPLE');
  const multipleSimpleBetIndex = betTypes.lastIndexOf('MULTI_SIMPLE');
  const lang = getLanguage();

  const handleInputPrice = (e) => {
    dispatch(updateStake(e.target.value));
  };

  const handleSubInputPrice = (e, index) => {
    dispatch(updateStake({ stake: e.target.value, index }));
  };

  const recompute = () => {
    dispatch(calculateOdds());
    dispatch(calculateGains());
    dispatch(applyBonus());
    dispatch(calculateTax());
  };

  const toggleMultiple = (value) => {
    dispatch(toggleMultipleBet(value));
  };

  const sendResumeBet = () => {
    let ticketValue = 3;
    let betObjList = [];
    if (betType === 'SIMPLE' || betType === 'MULTI_SIMPLE') {
      ticketValue = 1;
    } else if (betType === 'MULTIPLE') {
      ticketValue = 2;
    }

    if (multipleSimple) {
      betObjList = [...simpleTickets].map((simpleTicket) => (
        {
          lang,
          agentId: (userInfo?.agentId?.toString()),
          agentPwd: (userInfo?.agentPwd?.toString()),
          agentPhone: (userInfo?.agentPhone?.toString()),
          pick: pick.split('-')[simpleTicket.betIndex],
          amt: Number(simpleTicket.stake) === 0 ? Number(minStake) : Number(simpleTicket.stake),
          chl: userObj.chl,
          tkt: ticketValue,
          time: new Date().getTime(),
          dvr: 0.1,
          imei: userObj.imei,
          dtp: userObj.DT,
          kid: 1,
          ver: userObj.ver,

        }));
    } else {
      betObjList = [{
        lang,
        agentId: (userInfo?.agentId?.toString()),
        agentPwd: (userInfo?.agentPwd?.toString()),
        agentPhone: (userInfo?.agentPhone?.toString()),
        pick,
        amt: Number(stake) === 0 ? Number(minStake) : Number(stake),
        chl: userObj.chl,
        tkt: ticketValue,
        time: new Date().getTime(),
        dvr: 0.1,
        imei: userObj.imei,
        dtp: userObj.DT,
        kid: 1,
        ver: userObj.ver,
      }];
    }
    dispatch(resumeBet(betObjList));
    dispatch(showModal());
  };

  useEffect(() => {
    if (!multipleSimple) {
      const timeoutDuration = 500;
      const focusInput = () => {
        const timeout = setTimeout(() => {
          inputRef.current.focus();
        }, timeoutDuration);
        return () => clearTimeout(timeout);
      };

      if (bets.length > 0) {
        focusInput();
      }
    }
  }, [bets, multipleSimple]);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    dispatch(calculateOdds());
    dispatch(calculateGains());
    dispatch(applyBonus());
    dispatch(calculateTax());
    if (bets.length === 0) {
      dispatch(updateStake(minStake));
    }
  }, [minStake, stake, bets, integral, betType, dispatch]);
  const toggleMobileBetSlip = () => {
    if (mobileBetSlipClass === 'betslip hide-mobile-betslip') {
      setMobileBetSlipClass('betslip show-mobile-betslip');
    } else {
      setMobileBetSlipClass('betslip hide-mobile-betslip');
    }
  };

  let color;
  if (betStatus === 'Success!') {
    color = 'green';
  } else if (betStatus === 'Failed!') {
    color = 'red';
  } else { color = '#fff'; }
  const {
    msg74, msg75, msg76, msg77, msg78, msg79, msg153, msg145, msg149, msg157, msg158, msg162,
  } = translationKeys;

  const inValidStake = () => ((stake < minStake) || (stake > maxStake) || (stake % stepValue !== 0));
  const inValidSubStake = (value) => ((value < minStake) || (value > maxStake) || (value % stepValue !== 0));
  const validMultipleSimple = () => (simpleBetIndex > -1 && multipleBetIndex > -1 && multipleSimpleBetIndex > -1);
  const inValidMultipleSimple = () => multipleSimple && simpleTickets.some((simpleTicket) => inValidSubStake(simpleTicket.stake));

  const slipBody = () => {
    if ((simple || multiple) && !integral && !multipleSimple) {
      return (
        <ul className="match-list list-unstyled">
          { bets.map((item) => {
            const {
              match, odds, team, market, uniqueId, matchNumber,
            } = item;
            return (
              <li key={uniqueId}>
                <img src={`${imgUrl}${match?.info?.sport?.icon}` || defaultImage} alt="" />
                <div className="block-left">
                  <div className="match">
                    <span>{match?.info?.tournament?.name}</span>
                    <span>
                      {match?.info?.teamhome?.name}
                      {' '}
                      -
                      {' '}
                      {match?.info?.teamaway?.name}
                    </span>
                    <button
                      type="button"
                      className="btn btn-close"
                      onClick={() => {
                        dispatch(deleteFromBetSlip(uniqueId));
                      }}
                    />
                  </div>
                  <div className="market">
                    <span>
                      {market}
                      {' '}
                      -
                      {' '}
                      {team}
                    </span>
                    <span>{odds}</span>
                  </div>
                  <div className="market">
                    <span>{ matchNumber}</span>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      );
    }
    return (
      <ul className="ticket-list list-unstyled">
        {integral ? (
          integralTickets.map((integralTicket, index) => {
            const betIndexes = integralTicket.bets;
            const indexKey = betIndexes.reduce((acc, index) => acc + index.toString(), '');
            return (
              <li className="ticket" key={indexKey}>
                <span className="title">{`Ticket ${index + 1}`}</span>
                <div className="ctn-events" key={indexKey}>
                  <ul className="match-list list-unstyled">
                    {betIndexes.map((betIndex) => {
                      const {
                        match, odds, team, market, uniqueId, matchNumber,
                      } = bets[betIndex];
                      return (
                        <li key={`${indexKey}-${uniqueId}`}>
                          <img src={`${imgUrl}${match?.info?.sport?.icon}` || defaultImage} alt="" />
                          <div className="block-left">
                            <div className="match">
                              <span>{match?.info?.tournament?.name}</span>
                              <span>
                                {match?.info?.teamhome?.name}
                                {' '}
                                -
                                {' '}
                                {match?.info?.teamaway?.name}
                              </span>
                            </div>
                            <div className="market">
                              <span>
                                {market}
                                {' '}
                                -
                                {' '}
                                {team}
                              </span>
                              <span>{odds}</span>
                            </div>
                            <div className="market">
                              <span>{ matchNumber}</span>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="zone-bet">
                  <div className="line">
                    <span className="lib">{t(msg157)}</span>
                    <span className="val">{integralTicket.odds}</span>
                  </div>
                  <div className="line">
                    <span className="lib">{t(msg76)}</span>
                    <span className="val">
                      {inValidStake() ? '-' : formatCurrency(integralTicket.potentialWinnings, currencyConfigs)}
                    </span>
                  </div>
                  {integralTicket.bonus > 0 && (
                  <div className="line">
                    <span className="lib">
                      Bonus(
                      {`${integralTicket.bonusPercent}%`}
                      )
                    </span>
                    <span className="val">
                      {inValidStake() ? '-' : formatCurrency(integralTicket.bonus, currencyConfigs)}
                    </span>
                  </div>
                  )}
                  {integralTicket.bonus > 0 && (
                  <div className="line">
                    <span className="lib">
                      {`${t(msg158)} + Bonus(${bonusPercent}%)`}
                    </span>
                    <span className="val">
                      {inValidStake() ? '-' : formatCurrency(integralTicket.potentialWinningsWithBonus, currencyConfigs)}
                    </span>
                  </div>
                  )}
                  {integralTicket.TVA > 0 && (
                  <div className="line">
                    <span className="lib">{`${integralTicket.tvaConfig.value}% ${integralTicket.tvaConfig.type}`}</span>
                    <span className="val">
                      {inValidStake() ? '-' : formatCurrency(integralTicket.TVA, currencyConfigs)}
                    </span>
                  </div>
                  )}
                  <div className="line">
                    <span className="lib">{t(msg149)}</span>
                    <span className="val">
                      {inValidStake() ? '-' : formatCurrency(integralTicket.finalWinnings, currencyConfigs)}
                    </span>
                  </div>
                </div>
              </li>
            );
          })
        ) : (
          simpleTickets.map((simpleTicket, index) => {
            const betIndex = simpleTicket.betIndex;
            const {
              match, odds, team, market, uniqueId, matchNumber,
            } = bets[betIndex];
            return (
              <li className="ticket" key={`simple-ticket-${betIndex}`}>
                <span className="title">{`Ticket ${index + 1}`}</span>
                <div className="ctn-events" key={`simple-ticket-info-${betIndex}`}>
                  <ul className="match-list list-unstyled">
                    <li key={`${betIndex}-${uniqueId}`}>
                      <img src={`${imgUrl}${match?.info?.sport?.icon}` || defaultImage} alt="" />
                      <div className="block-left">
                        <div className="match">
                          <span>{match?.info?.tournament?.name}</span>
                          <span>
                            {match?.info?.teamhome?.name}
                            {' '}
                            -
                            {' '}
                            {match?.info?.teamaway?.name}
                          </span>
                          <button
                            type="button"
                            className="btn btn-close"
                            onClick={() => {
                              dispatch(deleteFromBetSlip(uniqueId));
                            }}
                          />
                        </div>
                        <div className="market">
                          <span>
                            {market}
                            {' '}
                            -
                            {' '}
                            {team}
                          </span>
                          <span>{odds}</span>
                        </div>
                        <div className="market">
                          <span>{ matchNumber}</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="zone-bet">
                  <div className="line">
                    <span className="lib">{t(msg157)}</span>
                    <span className="val">{simpleTicket.odds}</span>
                  </div>
                  <div className="line">
                    <span className="lib">{t(msg75)}</span>
                    <div className="input-betslip">
                      <div className="input-group d-flex align-items-center justify-content-center">
                        <span className="text-bold">1x</span>
                      </div>
                      <div className="input-group">
                        <button
                          className="btn"
                          type="button"
                          disabled={simpleTicket.stake <= minStake}
                          onClick={() => {
                            dispatch(stepStake({ increment: false, index: simpleTicket.betIndex }));
                            recompute();
                          }}
                        >
                          <img src={dash} alt="" />
                        </button>
                        <div className="form-label-stake">
                          <input
                            id={`stake-${index}`}
                            type="text"
                            placeholder={minStake}
                            className="form-control"
                            onFocus={((e) => e.target.select())}
                            name="stake"
                            onKeyDown={(e) => !(/^\d+$/.test(e.key) || e.key === 'Backspace' || e.key === 'Delete') && e.preventDefault()}
                            disabled={bets.length < 1}
                            value={simpleTicket.stake}
                            onChange={(e) => handleSubInputPrice(e, simpleTicket.betIndex)}
                          />
                        </div>
                        <button
                          className="btn"
                          type="button"
                          disabled={simpleTicket.stake >= maxStake}
                          onClick={() => {
                            dispatch(stepStake({ increment: true, index: simpleTicket.betIndex }));
                            recompute();
                          }}
                        >
                          <img src={plus} alt="" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="line">
                    <span className="lib">{t(msg76)}</span>
                    <span className="val">
                      {inValidSubStake(simpleTicket.stake) ? '-' : formatCurrency(simpleTicket.potentialWinnings, currencyConfigs)}
                    </span>
                  </div>
                  {simpleTicket.bonus > 0 && (
                  <div className="line">
                    <span className="lib">
                      Bonus(
                      {`${simpleTicket.bonusPercent}%`}
                      )
                    </span>
                    <span className="val">
                      {inValidSubStake(simpleTicket.stake) ? '-' : formatCurrency(simpleTicket.bonus, currencyConfigs)}
                    </span>
                  </div>
                  )}
                  {simpleTicket.bonus > 0 && (
                  <div className="line">
                    <span className="lib">
                      {`${t(msg158)} + Bonus(${bonusPercent}%)`}
                    </span>
                    <span className="val">
                      {inValidSubStake(simpleTicket.stake) ? '-' : formatCurrency(simpleTicket.potentialWinningsWithBonus, currencyConfigs)}
                    </span>
                  </div>
                  )}
                  {simpleTicket.TVA > 0 && (
                  <div className="line">
                    <span className="lib">{`${simpleTicket.tvaConfig.value}% ${simpleTicket.tvaConfig.type}`}</span>
                    <span className="val">
                      {inValidSubStake(simpleTicket.stake) ? '-' : formatCurrency(simpleTicket.TVA, currencyConfigs)}
                    </span>
                  </div>
                  )}
                  <div className="line">
                    <span className="lib">{t(msg149)}</span>
                    <span className="val">
                      {inValidSubStake(simpleTicket.stake) ? '-' : formatCurrency(simpleTicket.finalWinnings, currencyConfigs)}
                    </span>
                  </div>
                </div>
              </li>
            );
          })
        )}
      </ul>
    );
  };
  return (
    <>
      <button
        type="button"
        className="btn btn-primary btn-betslip d-md-none mobile-betslip-btn"
        onClick={toggleMobileBetSlip}
      >
        <img src={ticket} alt="" />
        {simple || multiple ? bets.length : numIntegralTickets }
      </button>
      <div className={isMobile ? mobileBetSlipClass : 'betslip'}>
        <form
          className="betslip-form-main"
          onSubmit={(event) => {
            event.preventDefault();
            sendResumeBet();
          }}
        >
          <div className="content d-sm-block text-center">
            <div className="betslip">

              {isMobile ? (
                <button
                  className="head"
                  type="button"
                  onClick={() => {
                    toggleMobileBetSlip();
                  }}
                >
                  {t(msg153)}
                  <span>
                    <IoMdClose style={{ color: 'white', fontSize: '1.5rem' }} />
                  </span>
                </button>
              ) : (
                <div className="head">
                  {t(msg153)}
                  <span>{simple || multiple ? bets.length : numIntegralTickets }</span>
                </div>
              ) }
              {betType !== '' && (
              <div className="sub-header">
                <select name="" id="bet-type-select" defaultValue={betType} onChange={(e) => toggleMultiple(e.target.value)}>
                  <option value={betType}>{betType === betTypes[multipleSimpleBetIndex] ? betTypes[simpleBetIndex] : betType }</option>
                  { multipleSimple && validMultipleSimple() ? (<option value={betTypes[multipleBetIndex]}>{betTypes[multipleBetIndex]}</option>) : null}
                  { multiple && validMultipleSimple() ? (<option value={betTypes[multipleSimpleBetIndex]}>{betTypes[simpleBetIndex]}</option>) : null}
                </select>
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={() => {
                    dispatch(clearBetSlip());
                  }}
                >
                  {t(msg74)}
                  <img src={trash} alt="" />
                </button>
              </div>
              )}
              <div className="slip-body">
                <div className={`ctn-events${integral ? ' integral' : ''}${multipleSimple ? ' multiple-simple' : ''}`}>
                  {slipBody()}
                </div>
                <div className="zone-bet">
                  {(simple || multiple)
                    && (
                    <div className="line">
                      <span className="lib">{t(msg157)}</span>
                      <span className="val">{odds}</span>
                    </div>
                    )}
                  {(simple || multiple || integral) && (
                  <div className="line">
                    <span className="lib">{t(msg75)}</span>
                    <div className="input-betslip">
                      {(simple || multiple)
                      && (
                      <div className="input-group d-flex align-items-center justify-content-center">
                        <span className="text-bold">1x</span>
                      </div>
                      )}
                      {(integral)
                      && (
                      <div className="input-group d-flex align-items-center justify-content-center">
                        <span className="text-bold">
                          {numIntegralTickets}
                          x
                        </span>
                      </div>
                      )}
                      <div className="input-group">
                        <button
                          className="btn"
                          type="button"
                          disabled={bets.length < 1 || stake <= minStake}
                          onClick={() => dispatch(stepStake({ increment: false }))}
                        >
                          <img src={dash} alt="" />
                        </button>
                        <div className="form-label-stake">
                          <input
                            id="stake"
                            type="text"
                            placeholder={bets.length > 0 ? minStake : 0}
                            className="form-control"
                            onFocus={((e) => e.target.select())}
                            name="stake"
                            onKeyDown={(e) => !(/^\d+$/.test(e.key) || e.key === 'Backspace' || e.key === 'Delete') && e.preventDefault()}
                            ref={inputRef}
                            disabled={bets.length < 1}
                            value={bets.length > 0 ? stake : 0}
                            onChange={handleInputPrice}
                          />
                        </div>
                        <button
                          className="btn"
                          type="button"
                          disabled={bets.length < 1 || stake >= maxStake}
                          onClick={() => dispatch(stepStake({ increment: true }))}
                        >
                          <img src={plus} alt="" />
                        </button>
                      </div>
                    </div>
                  </div>
                  )}
                  {(exciseTax > 0) && (
                    <div className="line">
                      <span className="lib">{`${exciseTaxConfig.value}% ${exciseTaxConfig.type}`}</span>
                      <span className="val">
                        {inValidStake() ? '-' : formatCurrency(exciseTax, currencyConfigs)}
                      </span>
                    </div>
                  )}
                  {(stakeWithoutTax > 0 && exciseTax > 0) && (
                    <div className="line">
                      <span className="lib">{t(msg145)}</span>
                      <span className="val">
                        {inValidStake() ? '-' : formatCurrency(stakeWithoutTax, currencyConfigs)}
                      </span>
                    </div>
                  )}
                  {((simple || multiple)) && (
                    <div className="line">
                      <span className="lib">{t(msg76)}</span>
                      <span className="val">
                        {inValidStake() ? '-' : formatCurrency(potentialWinnings, currencyConfigs)}
                      </span>
                    </div>
                  )}
                  {((simple || multiple) && bonus > 0) && (
                    <div className="line">
                      <span className="lib">
                        Bonus(
                        {`${bonusPercent}%`}
                        )
                      </span>
                      <span className="val">
                        {inValidStake() ? '-' : formatCurrency(bonus, currencyConfigs)}
                      </span>
                    </div>
                  )}
                  {((simple || multiple) && bonus > 0) && (
                    <div className="line">
                      <span className="lib">
                        {`${t(msg158)} + Bonus(${bonusPercent}%)`}
                      </span>
                      <span className="val">
                        {inValidStake() ? '-' : formatCurrency(potentialWinningsWithBonus, currencyConfigs)}
                      </span>
                    </div>
                  )}
                  {((simple || multiple) && TVA > 0) && (
                    <div className="line">
                      <span className="lib">{`${tvaConfig.value}% ${tvaConfig.type}`}</span>
                      <span className="val">
                        {inValidStake() ? '-' : formatCurrency(TVA, currencyConfigs)}
                      </span>
                    </div>
                  )}
                  {((simple || multiple)) && (
                    <div className="line">
                      <span className="lib">{t(msg149)}</span>
                      <span className="val">
                        {inValidStake() ? '-' : formatCurrency(finalWinnings, currencyConfigs)}
                      </span>
                    </div>
                  )}
                  {integral && (
                  <div className="line">
                    <span className="lib">{t(msg162)}</span>
                    <span className="val">
                      {inValidStake() ? '-' : formatCurrency(integralTickets.reduce((acc, ticket) => acc + ticket.potentialWinningsWithBonus, 0), currencyConfigs)}
                    </span>
                  </div>
                  )}
                  {((simple || multiple)) && (
                  <div className="amt-final">
                    <span>{t(msg77)}</span>
                    <p>
                      {inValidStake() ? '-' : formatCurrency(stake, currencyConfigs)}
                    </p>
                  </div>
                  )}
                  {(integral) && (
                  <div className="amt-final">
                    <span>{t(msg77)}</span>
                    <p>
                      {inValidStake() ? '-' : formatCurrency(stake * numIntegralTickets, currencyConfigs)}
                    </p>
                  </div>
                  )}
                  {(multipleSimple) && (
                  <div className="amt-final">
                    <span>{t(msg77)}</span>
                    <p>
                      {inValidStake() ? '-' : formatCurrency(simpleTickets.reduce((acc, ticket) => acc + ticket.stake, 0), currencyConfigs)}
                    </p>
                  </div>
                  )}

                  <div className="line d-none">
                    <div className="form-check form-switch">
                      <label className="form-check-label" htmlFor="flexSwitchCheckChecked">
                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" defaultChecked />
                        {t(msg78)}
                      </label>
                    </div>
                  </div>
                  {((alerts.length > 0 && betType === 'SIMPLE') || (alerts.length > 0 && betType === 'MULTIPLE')) && <ShowAlert />}
                  <div className="actions">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      style={{ color }}
                      disabled={bets.length === 0 || inValidStake() || inValidMultipleSimple()}
                    >
                      {resumeBetLoading && (
                      <span className="spinner-border spinner-border-sm" />
                      )}
                      {t(msg79)}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      {(showRechargeConfirm || errorGetBalance || canPrint || loadingHistory || histMsg || resumeBetLoading || msg
        || isbalanceLoading || checkWithdrawLoading || isErrorVerifyTckt || isTicketLoading || isCancelTicketLoading
        || isPlaceBetLoading || isPaidLoading || isdepositLoading || isBonRechargeLoading || withdrawWithCodeLoading
        || verifyTicketMsg || errorBonRecharge || rechargeSuccess || failedCheckWithdraw || canWithdraw || canNotWithdraw
        || bonRechargeSuccess || successGetBalance || showBonRechargeConfirm || failedPayTicket || showPayConfirm
        || showCancelConfirm || ticketPaid || success || showPreview || successfetchTicket || ticketCanceled || failedCancelTicket
        || ticketNotRegistered || ticketAlreadyCanceled || resetLoading || resetMessage || resetSuccessMsg) && <Modal />}
      {resetPinForm && <ResetPinComponent />}
    </>
  );
};

export default RightSideBar;
