/* eslint max-len: 0 */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import defaultSportId from 'components/helpers/defaultSport';
import '../../../../styles/_sports.scss';
import Loading from 'components/common/Loading';
import Error from 'components/common/Error';
import { getTodayMatches } from 'redux/today-matches/todayMatchesSlice';
import translationKeys from 'translations/translationKeys';
import getSortedMatches from 'components/helpers/sortedMatches';
import { selectSport } from 'redux/all-sports/allSportsSLice';
import SportItem from '../SportItem';
import defaultImage from '../../../../assets/images/mathys/png/football.png';
import baseUrls from '../../../../config';

const TodayMatches = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const imgUrl = baseUrls.BASE_URL_IMG;
  const navigate = useNavigate();
  const selectedTodaySport = JSON.parse(localStorage.getItem('selectedTodaySport')) ? JSON.parse(localStorage.getItem('selectedTodaySport')) : defaultSportId; // Set in Login.jsx.
  const { msg154 } = translationKeys;
  const {
    todayMatches, successTodayMatches, isLoading, error,
  } = useSelector((store) => store.todayMatches);
  const { allSports } = useSelector((store) => store.allSports);
  const [sortedMatches, setSortedMatches] = useState([]);
  const [selectedSport, setSelectedSport] = useState(selectedTodaySport);
  // When page is refreshed and path is /dashboard/today-matches, call today matches so data is not lost.
  useEffect(() => {
    if (!successTodayMatches) {
      dispatch(getTodayMatches(selectedSport));
      dispatch(selectSport(selectedSport));
    }

    if (successTodayMatches) {
      setSortedMatches(getSortedMatches(todayMatches));
    }
  }, [successTodayMatches, selectedTodaySport, todayMatches, dispatch]);
  return (
    <>
      <div className="list-dashboard">
        <div className="content-wrap">
          <div className="zone-nav">
            <ul className="nav nav-pills list-inline">
              {allSports.map((item) => (
                <li className="nav-item list-inline-item" key={item.id}>
                  <button
                    type="button"
                    className={`nav-link ${item.id === selectedSport ? 'active' : ''}`}
                    onClick={() => {
                      setSelectedSport(item.id);
                      dispatch(getTodayMatches(item.id));
                      localStorage.setItem('selectedTodaySport', JSON.stringify(item.id)); // To be used when page is refreshed to refetch today matches
                      navigate('/dashboard/today-matches');
                    }}
                  >
                    <img
                      src={imgUrl + item.icon || defaultImage}
                      alt={item.name}
                    />
                    <span>{item.name}</span>
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <div className="tab-content">
            <div className="match-result">
              {isLoading && <Loading />}
              {error && <Error text={t(error)} />}
              {!isLoading && !error && (
              <ul className="list-unstyled">
                { (sortedMatches.length > 0 && sortedMatches.map((match) => <SportItem event={match} key={match.id + uuidv4()} />)) || <Error text={t(msg154)} /> }
              </ul>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TodayMatches;
