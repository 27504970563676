/* eslint  prefer-destructuring: 0 */
/* eslint  max-len: 0 */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../../../../styles/_home-detail.scss';
import { v4 as uuidv4 } from 'uuid';
import { getHistory, resetHistoryLoaded } from 'redux/history/historySlice';
import translationKeys from 'translations/translationKeys';
import clock from '../../../../assets/images/mathys/svg/clock.svg';

const History = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const { pathname } = location;
  const { history } = useSelector((store) => store.history);
  const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const {
    msg17, msg19, msg57, msg58, msg59, msg60, msg61, msg62, msg67, msg18, msg68, msg54, msg64, msg65, msg66, msg86, msg87, msg88, msg121,
  } = translationKeys;
  const buttonsText = [{
    text: t(msg57),
    querry: 'BET',
  },
  {
    text: t(msg58),
    querry: 'CANCEL',
  },
  { text: t(msg59), querry: 'WINING_TICKET' }, { text: t(msg60), querry: 'DIRECT_RECHARGE' }, { text: t(msg19), querry: 'WITHDRAW' }, { text: t(msg61), querry: 'COUPON_RECHARGE' }];
  const handleSubmit = (e) => {
    e.preventDefault();
    const startDateFormat = new Date(startDate);
    startDateFormat.setHours(0, 0, 0, 0);
    const startUnixTimestamp = Math.floor(startDateFormat.getTime() / 1000);
    const endDateFormat = new Date(endDate);
    endDateFormat.setHours(23, 59, 59, 999);
    const endUnixTimestamp = Math.floor(endDateFormat.getTime() / 1000);
    dispatch(getHistory({
      startDate: startUnixTimestamp,
      endDate: endUnixTimestamp,
      agentId: userInfo.agentId,
      type: '',
    }));
  };
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (name === 'startDate') {
      setStartDate(value);
    } else if (name === 'endDate') {
      setEndDate(value);
    }
  };
  const handleButtonClick = (item) => {
    const startDateFormat = new Date(startDate);
    startDateFormat.setHours(0, 0, 0, 0);
    const startUnixTimestamp = Math.floor(startDateFormat.getTime() / 1000);
    const endDateFormat = new Date(endDate);
    endDateFormat.setHours(23, 59, 59, 999);
    const endUnixTimestamp = Math.floor(endDateFormat.getTime() / 1000);
    dispatch(getHistory({
      startDate: startUnixTimestamp,
      endDate: endUnixTimestamp,
      agentId: userInfo.agentId,
      type: item.querry,
    }));
  };
  useEffect(() => {
    dispatch(resetHistoryLoaded());
  }, [pathname, dispatch]);
  const isDateFieldEmpty = startDate === '' || endDate === '';

  return (
    <>
      <div className="details history">
        <div className="header">
          <img src={clock} alt="" />
          {t(msg17)}
        </div>
        <div className="content">
          <form action="" onSubmit={handleSubmit}>
            <div className="inputs-container">
              <label htmlFor="startDate" className="form-label label">
                {t(msg62)}
                <input
                  type="date"
                  className="form-control"
                  id="startDate"
                  name="startDate"
                  placeholder="dd/MM/YYYY"
                  onChange={handleChange}
                  required
                />
              </label>
            </div>
            <div className="inputs-container">
              <label htmlFor="endDate" className="form-label label">
                {t(msg68)}
                <input
                  type="date"
                  className="form-control"
                  id="endDate"
                  name="endDate"
                  placeholder="dd/MM/YYYY"
                  onChange={handleChange}
                  required
                />
              </label>
            </div>
            <button
              type="submit"
              className="btn btn-secondary"
              disabled={isDateFieldEmpty}
            >
              <span>{t(msg18)}</span>
            </button>
          </form>
          <div className="tab-content">
            <div className="statut-filter-control">
              {buttonsText.map((item) => (
                <button
                  key={uuidv4()}
                  type="button"
                  className="btn btn-outline-secondary text-capitalize"
                  onClick={() => handleButtonClick(item)}
                  disabled={isDateFieldEmpty}
                >
                  {t(item.text)}
                </button>
              ))}
            </div>
            {history && history.length > 0 ? (
              <div className="history-data">
                <table className="table">
                  <thead className="table-light">
                    <tr className="table-dark">
                      <th scope="col" className="text-light">{t('Date')}</th>
                      <th scope="col">{t(msg64)}</th>
                      <th scope="col">{t(msg54)}</th>
                      <th scope="col">{t(msg65)}</th>
                      <th scope="col">{t(msg66)}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {history.map((item) => {
                      const timestamp = item.dtCREATED;
                      const date = new Date(timestamp);

                      let refCode;
                      if ((/"([^"]*)"/.test(item.str_REF_CODE)) || (/'([^']*)'/.test(item.str_REF_CODE))) {
                        const array = JSON.parse(item.str_REF_CODE.replace(/'/g, '"')); // Replace single quotes with double quotes and parse as JSON
                        refCode = array.join(', ');
                      } else {
                        refCode = item.str_REF_CODE;
                      }
                      let type;
                      if (item.str_type === 'BET') {
                        type = msg86;
                      } else if (item.str_type === 'CANCEL') {
                        type = msg87;
                      } else if (item.str_type === 'WINNING_TICKET') {
                        type = msg88;
                      } else if (item.str_type === 'DIRECT_RECHARGE') {
                        type = msg121;
                      } else if (item.str_type === 'WITHDRAW') {
                        type = msg19;
                      } else if (item.str_type === 'COUPON_RECHARGE') {
                        type = msg61;
                      }
                      const options = {
                        year: 'numeric', month: '2-digit', day: '2-digit', hour: 'numeric', minute: 'numeric', second: 'numeric', hour24: true,
                      };
                      const formattedDate = date.toLocaleString('fr-CG', options); // Adjust locale as needed
                      return (
                        <tr key={uuidv4()}>
                          <td>{formattedDate}</td>
                          <td>{t(type)}</td>
                          <td>{Math.abs(item.intAMOUNT)}</td>
                          <td>{refCode}</td>
                          <td>{item.lgAgentTransactionID}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>

              </div>
            ) : <div className="no-data">{t(msg67)}</div>}

          </div>
        </div>
      </div>
    </>
  );
};

export default History;
