import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

const VerifyTicketContent = ({ arr }) => (
  <div>
    {arr && arr.length > 0 && arr[0].data && arr[0].data.map((item) => {
      const { data } = item;
      return (
        <div className="preview-data-solde" key={uuidv4()}>
          {data && data.slice(5).map((d) => (
            <pre style={{ padding: '0', textAlign: 'start' }} key={uuidv4()}>{d}</pre>
          ))}
        </div>
      );
    })}
  </div>
);

const ResumeBetContent = ({ arr }) => (
  <div className={`preview-data-solde ${arr.length > 1 ? 'flex' : ''}`}>
    {
    arr.length > 1 && arr.map((info, index) => (
      <div className="ticket-section" key={`Ticket-resume-${index + 1}`}>
        <pre className="ticket-number" key={uuidv4()}>{`Ticket ${index + 1}`}</pre>
        {
          (info.length > 0 && info.slice(5).map((item) => (
            <pre style={{ padding: '0', textAlign: 'start' }} key={uuidv4()}>{item}</pre>
          )))
        }
      </div>
    ))
    }
    {
    arr.length === 1 && arr.map((info) => (
      info.length > 0 && info.slice(5).map((item) => (
        <pre style={{ padding: '0', textAlign: 'start' }} key={uuidv4()}>{item}</pre>
      ))
    ))
    }
  </div>
);
const SoldeContent = ({ arr }) => (
  <div className="preview-data-solde">
    {arr && arr.length > 0 && arr.slice(2).map((item) => (
      <pre style={{ padding: '0', textAlign: 'start' }} key={uuidv4()}>{item}</pre>
    ))}
  </div>
);
const MessageContent = ({ text, color }) => (
  <p style={{ color, fontSize: '1.2rem' }}>{text}</p>
);

MessageContent.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

VerifyTicketContent.propTypes = {
  arr: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          data: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
        }).isRequired,
      ).isRequired,
    }).isRequired,
  ).isRequired,
};

ResumeBetContent.propTypes = {
  arr: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};
SoldeContent.propTypes = {
  arr: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};

export {
  VerifyTicketContent, ResumeBetContent, MessageContent, SoldeContent,
};
