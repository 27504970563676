import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// import PropTypes from 'prop-types';

const ShowAlert = () => {
  const { alerts } = useSelector((store) => store.betSlip);
  const { t } = useTranslation();
  const [errorMessages, setErrorMessages] = useState([]);

  useEffect(() => {
    const priorityIndex = alerts.findIndex((alert) => (alert.type === 'minStake' || alert.type === 'maxStake'));

    if (priorityIndex > -1) {
      setErrorMessages(new Array(
        <p>
          {t(alerts[priorityIndex].displayText)}
          {' '}
          {alerts[priorityIndex].alertMessage}
        </p>,
      ));
    } else {
      const messages = [];
      for (let i = 0; i < alerts.length; i += 1) {
        messages.push(
          <p>
            {t(alerts[i].displayText)}
            {' '}
            {alerts[i].alertMessage}
          </p>,
        );
      }
      setErrorMessages(messages);
    }
  }, [alerts]);

  return (
    <div className="line">
      <div className="div-msg">
        <span>Info!</span>
        {errorMessages}
      </div>
    </div>
  );
};

// ShowAlert.propTypes = {
//   text: PropTypes.string.isRequired,
// };

export default ShowAlert;
